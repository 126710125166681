import _ethQuery from "eth-query";
import _pify from "pify";
import _safeEventEmitter from "safe-event-emitter";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
const EthQuery = _ethQuery;
const pify = _pify;
const SafeEventEmitter = _safeEventEmitter;
const sec = 1000;

const calculateSum = (accumulator, currentValue) => accumulator + currentValue;

const blockTrackerEvents = ["sync", "latest"];

class BaseBlockTracker extends SafeEventEmitter {
  //
  // public
  //
  constructor(opts = {}) {
    super(); // config

    (this || _global)._blockResetDuration = opts.blockResetDuration || 20 * sec; // state

    (this || _global)._blockResetTimeout;
    (this || _global)._currentBlock = null;
    (this || _global)._isRunning = false; // bind functions for internal use

    (this || _global)._onNewListener = (this || _global)._onNewListener.bind(this || _global);
    (this || _global)._onRemoveListener = (this || _global)._onRemoveListener.bind(this || _global);
    (this || _global)._resetCurrentBlock = (this || _global)._resetCurrentBlock.bind(this || _global); // listen for handler changes

    this._setupInternalEvents();
  }

  isRunning() {
    return (this || _global)._isRunning;
  }

  getCurrentBlock() {
    return (this || _global)._currentBlock;
  }

  async getLatestBlock() {
    // return if available
    if ((this || _global)._currentBlock) return (this || _global)._currentBlock; // wait for a new latest block

    const latestBlock = await new Promise(resolve => this.once("latest", resolve)); // return newly set current block

    return latestBlock;
  } // dont allow module consumer to remove our internal event listeners


  removeAllListeners(eventName) {
    // perform default behavior, preserve fn arity
    if (eventName) {
      super.removeAllListeners(eventName);
    } else {
      super.removeAllListeners();
    } // re-add internal events


    this._setupInternalEvents(); // trigger stop check just in case


    this._onRemoveListener();
  } //
  // to be implemented in subclass
  //


  _start() {// default behavior is noop
  }

  _end() {} // default behavior is noop
  //
  // private
  //


  _setupInternalEvents() {
    // first remove listeners for idempotence
    this.removeListener("newListener", (this || _global)._onNewListener);
    this.removeListener("removeListener", (this || _global)._onRemoveListener); // then add them

    this.on("newListener", (this || _global)._onNewListener);
    this.on("removeListener", (this || _global)._onRemoveListener);
  }

  _onNewListener(eventName, handler) {
    // `newListener` is called *before* the listener is added
    if (!blockTrackerEvents.includes(eventName)) return;

    this._maybeStart();
  }

  _onRemoveListener(eventName, handler) {
    // `removeListener` is called *after* the listener is removed
    if (this._getBlockTrackerEventCount() > 0) return;

    this._maybeEnd();
  }

  _maybeStart() {
    if ((this || _global)._isRunning) return;
    (this || _global)._isRunning = true; // cancel setting latest block to stale

    this._cancelBlockResetTimeout();

    this._start();
  }

  _maybeEnd() {
    if (!(this || _global)._isRunning) return;
    (this || _global)._isRunning = false;

    this._setupBlockResetTimeout();

    this._end();
  }

  _getBlockTrackerEventCount() {
    return blockTrackerEvents.map(eventName => this.listenerCount(eventName)).reduce(calculateSum);
  }

  _newPotentialLatest(newBlock) {
    const currentBlock = (this || _global)._currentBlock; // only update if blok number is higher

    if (currentBlock && hexToInt(newBlock) <= hexToInt(currentBlock)) return;

    this._setCurrentBlock(newBlock);
  }

  _setCurrentBlock(newBlock) {
    const oldBlock = (this || _global)._currentBlock;
    (this || _global)._currentBlock = newBlock;
    this.emit("latest", newBlock);
    this.emit("sync", {
      oldBlock,
      newBlock
    });
  }

  _setupBlockResetTimeout() {
    // clear any existing timeout
    this._cancelBlockResetTimeout(); // clear latest block when stale


    (this || _global)._blockResetTimeout = setTimeout((this || _global)._resetCurrentBlock, (this || _global)._blockResetDuration); // nodejs - dont hold process open

    if ((this || _global)._blockResetTimeout.unref) {
      (this || _global)._blockResetTimeout.unref();
    }
  }

  _cancelBlockResetTimeout() {
    clearTimeout((this || _global)._blockResetTimeout);
  }

  _resetCurrentBlock() {
    (this || _global)._currentBlock = null;
  }

}

exports = BaseBlockTracker;

function hexToInt(hexInt) {
  return Number.parseInt(hexInt, 16);
}

export default exports;