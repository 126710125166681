import _pify from "pify";
import _base from "./base";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
const pify = _pify;
const BaseBlockTracker = _base;
const sec = 1000;

class PollingBlockTracker extends BaseBlockTracker {
  constructor(opts = {}) {
    // parse + validate args
    if (!opts.provider) throw new Error("PollingBlockTracker - no provider specified.");
    const pollingInterval = opts.pollingInterval || 20 * sec;
    const retryTimeout = opts.retryTimeout || pollingInterval / 10;
    const keepEventLoopActive = opts.keepEventLoopActive !== undefined ? opts.keepEventLoopActive : true;
    const setSkipCacheFlag = opts.setSkipCacheFlag || false; // BaseBlockTracker constructor

    super(Object.assign({
      blockResetDuration: pollingInterval
    }, opts)); // config

    (this || _global)._provider = opts.provider;
    (this || _global)._pollingInterval = pollingInterval;
    (this || _global)._retryTimeout = retryTimeout;
    (this || _global)._keepEventLoopActive = keepEventLoopActive;
    (this || _global)._setSkipCacheFlag = setSkipCacheFlag;
  } //
  // public
  //
  // trigger block polling


  async checkForLatestBlock() {
    await this._updateLatestBlock();
    return await this.getLatestBlock();
  } //
  // private
  //


  _start() {
    this._performSync().catch(err => this.emit("error", err));
  }

  async _performSync() {
    while ((this || _global)._isRunning) {
      try {
        await this._updateLatestBlock();
        await timeout((this || _global)._pollingInterval, !(this || _global)._keepEventLoopActive);
      } catch (err) {
        const newErr = new Error(`PollingBlockTracker - encountered an error while attempting to update latest block:\n${err.stack}`);

        try {
          this.emit("error", newErr);
        } catch (emitErr) {
          console.error(newErr);
        }

        await timeout((this || _global)._retryTimeout, !(this || _global)._keepEventLoopActive);
      }
    }
  }

  async _updateLatestBlock() {
    // fetch + set latest block
    const latestBlock = await this._fetchLatestBlock();

    this._newPotentialLatest(latestBlock);
  }

  async _fetchLatestBlock() {
    const req = {
      jsonrpc: "2.0",
      id: 1,
      method: "eth_blockNumber",
      params: []
    };
    if ((this || _global)._setSkipCacheFlag) req.skipCache = true;
    const res = await pify(cb => (this || _global)._provider.sendAsync(req, cb))();
    if (res.error) throw new Error(`PollingBlockTracker - encountered error fetching block:\n${res.error}`);
    return res.result;
  }

}

exports = PollingBlockTracker;

function timeout(duration, unref) {
  return new Promise(resolve => {
    const timoutRef = setTimeout(resolve, duration); // don't keep process open

    if (timoutRef.unref && unref) {
      timoutRef.unref();
    }
  });
}

export default exports;